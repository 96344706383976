import { defineStore } from 'pinia'

export const useTodosStore = defineStore('todos', {
  state: () => ({
    /** @type {{ text: string, id: number, isFinished: boolean }[]} */
    todos: [],
    /** @type {'all' | 'finished' | 'unfinished'} */
    filter: 'all',
    // type will be automatically inferred to number
    nextId: 0,
    drawer: true,
    userLogin: null,
    count: 0,
    products: [
      {
        title: 'Item #1',
        value: 1,
      },
      {
        title: 'Item #2',
        value: 2,
      },
      {
        title: 'Item #3',
        value: 3,
      },
    ],
  }),
  getters: {
    finishedTodos(state) {
      // autocompletion! ✨
      return state.todos.filter((todo) => todo.isFinished)
    },
    unfinishedTodos(state) {
      return state.todos.filter((todo) => !todo.isFinished)
    },
    loggedIn(state) {
      return state.userLogin

    },
    // drawerUStatus(state) {
    //   return state.drawerU
    // }

  },
  actions: {
    // any amount of arguments, return a promise or not
    addTodo(text) {
      // you can directly mutate the state
      this.todos.push({ text, id: this.nextId++, isFinished: false })
    },
    login({ commit }, user) {
      commit('setUserLogin', user)
    },
    drawerStatus(drawer) {
      this.drawer = !drawer
    }
  },
})



