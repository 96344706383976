<template>
    <v-footer
        absolute
        class="bg-primary"
    >
        <v-row
            justify="center"
            no-gutters
        >
            <v-btn
                v-for="link in links"
                :key="link"
                color="white"
                variant="text"
                class="my-2"
            >
                {{ link }}
            </v-btn>
            <v-col
                class="text-center mt-4"
                cols="12"
            >
                {{ new Date().getFullYear() }} — <strong>eSignGold</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: 'DefaultFooter',
    data() {
        return {
            links: [
                'Home',
                'About Us',
                'Team',
                'Services',
                'Blog',
                'Contact Us',
            ],

        }
    }
}
</script>