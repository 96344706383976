export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n traducidoo!!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "uploadTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Templates"])},
  "uploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Documents"])},
  "manageTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "manageDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "sendInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invite to sign"])},
  "docList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document List"])},
  "folders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folders"])},
  "signerAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer Administration"])},
  "signerAdminGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Signers & Groups"])},
  "teamAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Administration"])},
  "notificationCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Center"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilee"])},
  "businessDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Details"])},
  "billingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Information"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" is required"])},
  "mustValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be valid"])},
  "charLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" must be less than 45 characters"])},
  "howItWorksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How It Works!"])},
  "princingMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
  "contanctMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "apiMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API"])},
  "salesMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Sales"])},
  "buyNowMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now!"])},
  "tryForFreeMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try for Free!"])},
  "signInMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "freeTitlePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "freePlanPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "freePricePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
  "freePriceButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
  "proTitlePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
  "proPlanPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/month"])},
  "proPricePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.99"])},
  "proPriceButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Free Trial"])},
  "teamTitlePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
  "teamPlanPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/per month per user"])},
  "teamPricePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6.99"])},
  "teamPriceButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Free Trial"])},
  "enterpriseTitlePackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])},
  "enterprisePriceButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "enterpriseLookingForMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking for More?"])},
  "enterpriseTenOrMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 or more users?"])},
  "enterpriseLearnMoreAbout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More about our volume pricing or schedule a demo."])},
  "fourteenFreeTrial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14-day Free trial"])},
  "oneDocumentPerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Document per month"])},
  "oneSender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Sender"])},
  "unlimitedDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited documents"])},
  "upToNineSenders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 9 Senders"])},
  "multipleSigners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple signers"])},
  "templatesFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
  "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
  "signingLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing links"])},
  "teamFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team features"])},
  "contactSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "APIgotoDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to documentation"])},
  "mainFolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Folder"])},
  "document-P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "document-S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signed"])},
  "document-W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for Others"])},
  "document-C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "document-A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
  "document-D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
}