import { ref, readonly } from 'vue'

const overlay = ref(false);

function showOverlay() {
    overlay.value = true
}

function hideOverlay() {
    overlay.value = false
}

export default function useOverlayStore() {
    return {
        overlay: readonly(overlay),
        showOverlay,
        hideOverlay,
    };
}




