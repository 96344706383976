import { createRouter, createWebHistory } from 'vue-router'
import GHome from '@/views/HomeView.vue'
import { useTodosStore } from '@/store'
import $cookies from 'vue-cookies'
import useOverlayStore from '@/plugins/overlay-plugin'
const overlayPlugin = useOverlayStore()
const routes = [
  {
    path: '/',
    name: 'Home',
    component: GHome,//not lazy-load
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import('../views/AccessView.vue'), //lazy-load
  },
  {
    path: '/apiDocumentation',
    name: 'ApiDocumentation',
    component: () => import('../views/ApiDocumentationView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue'),
    children: [
      {
        path: 'welcome',
        name: 'welcome',  //translate on v-toolbar-title
        component: () => import('../views/dashboard/WelcomeView.vue'),
        meta: {
          authRequired: 'true',
          role: 'member'
        },
      },
      {
        path: 'templates',
        name: 'templates',
        component: () => import('../views/dashboard/TemplatesView.vue'),
        children: [
          {
            path: 'upload',
            name: 'uploadTemplates',
            component: () => import('../views/dashboard/TemplatesUploadView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
          {
            path: 'manage',
            name: 'manageTemplates',
            component: () => import('../views/dashboard/TemplatesManageView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
        ]
      },
      {
        path: 'documents',
        name: 'documents',
        children: [
          {
            path: 'upload',
            name: 'uploadDocuments',
            component: () => import('../views/dashboard/DocumentsUploadView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
          {
            path: 'manage',
            name: 'manageDocuments',
            component: () => import('../views/dashboard/DocumentsManageView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
        ]
      },
      {
        path: 'signer',
        name: 'signerAdmin',
        component: () => import('../views/dashboard/SignerAdministrationView.vue'),
        meta: {
          authRequired: 'true',
          role: 'member'
        },
      },
      {
        path: 'signerGroups',
        name: 'signerAdminGroups',
        component: () => import('../views/dashboard/SignerAdministrationGroupsView.vue'),
        meta: {
          authRequired: 'true',
          role: 'member'
        },
      },
      {
        path: 'team',
        name: 'teamAdmin',
        component: () => import('../views/dashboard/TeamAdministrationView.vue'),
        meta: {
          authRequired: 'true',
          role: 'member'
        },
      },
      {
        path: 'notification',
        name: 'notificationCenter',
        component: () => import('../views/dashboard/NotificationCenterView.vue'),
        meta: {
          authRequired: 'true',
          role: 'member'
        },
      },
      {
        path: 'settings',
        name: 'settings',
        children: [
          {
            path: 'profile',
            name: 'profile',
            component: () => import('../views/dashboard/SettingsProfileView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'

            },
          },
          {
            path: 'business',
            name: 'businessDetails',
            component: () => import('../views/dashboard/SettingsBusinessView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
          {
            path: 'billing',
            name: 'billingInfo',
            component: () => import('../views/dashboard/SettingsBillingView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
          {
            path: 'subscription',
            name: 'subscription',
            component: () => import('../views/dashboard/SettingsSubscriptionView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
          {
            path: 'permissions',
            name: 'permissions',
            component: () => import('../views/dashboard/SettingsPermissionsView.vue'),
            meta: {
              authRequired: 'true',
              role: 'member'
            },
          },
        ]
      },
    ],

  },
  {
    path: '/payment',
    name: 'paymentPage',
    component: () => import('../views/PaymentPageView.vue')
  },
  {
    path: '/signdocument',
    name: 'SigningView',
    component: () => import('../views/SigningView.vue')
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../views/UnauthorizedView.vue'),
    meta: {
      authRequired: 'false',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFoundView.vue')
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  //keep position on reload
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return {
  //       el: to.hash,
  //     }
  //   }
  // }
})

router.beforeEach(async (to, from, next) => {
  overlayPlugin.showOverlay();

  const store = useTodosStore()

  if ($cookies.isKey("user") && $cookies.get("user")) {
    store.$patch({
      //userLogin: {...store.userLogin, token: $cookies.isKey("token")}
      userLogin: $cookies.get("user")
    })
    $cookies.set('user', $cookies.get('user'));//renew cookie since route action
  } else {
    store.$patch({
      userLogin: null
    })
    //investigate how to reset store. see documentation
  }


  const isAuthenticated = store.userLogin?.token

  if (to.meta.authRequired === 'true') {//check page is protected or not
    //access check   
    if (isAuthenticated) {//store.getters.loggedIn //
      // if(!to.meta.role || store.getters.loggedIn.role.key === "super-admin"|| store.getters.loggedIn.role.key === to.meta.role){//if doenst need a role or is super admin or the role specified should match with the user role
      const redirectURL = sessionStorage.getItem('redirectURL')
      sessionStorage.setItem('redirectURL', '')
      if (redirectURL) {//if comes from login send to prev page user was
        next({ name: redirectURL })
      } else {//send to page desire
        next()
      }
      // }else{
      //   next({
      //     name: 'Unauthorized'
      //   })
      // }
      //next()
    } else {
      if (to.name !== 'Access') {//if is not Login save from what Url user comes to redirect once is logged
        sessionStorage.setItem('redirectURL', to.name)//if user lose session save from what Url user comes to be redirect once is logged
      }
      next({ name: 'Access' })//send to login first since is a auth page

    }
  } else {
    if (isAuthenticated && (to.name === 'Access' || to.name === 'Home')) { //store.getters.loggedIn //if user is already loggedIn and wants to access login or homepage avoid entrance and send to dashboard      //next({ name: 'welcome' })
      next({ name: 'welcome' })
    } else {
      next()
    }
  }
});

router.afterEach(() => {
  // Hide overlay after the route has been fully resolved
  overlayPlugin.hideOverlay()
});

export default router
