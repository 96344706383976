/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { loadFonts } from './webfontloader'
import vuetify from './vuetify'
//import pinia from '../store'
import router from '../router'
import i18n from '../i18n'
import { API } from '../api/api'
import GoogleSignInPlugin from "vue3-google-signin"
import VueAppleLogin from 'vue-apple-login'
import $cookies from 'vue-cookies'

import useOverlayStore from './overlay-plugin'
import SnackbarPlugin from './snackbar-plugin'

const overlayPlugin = useOverlayStore()

$cookies.config('30min')
import { createPinia } from 'pinia'
const pinia = createPinia()

export function registerPlugins(app) {
    app.config.globalProperties.$API = API
    app.provide('$overlay', overlayPlugin)
    loadFonts()
    app
        .use(vuetify)
        .use(pinia)
        .use(i18n)
        .use($cookies)
        .use(GoogleSignInPlugin, {
            clientId: '662441542301-cmjue3po6qlbn491kvmsgl5nrscs0i1v.apps.googleusercontent.com',
            scope: 'email',
            prompt: 'consent',
        })
        .use(VueAppleLogin, {
            clientId: 'com.example.signin',
            scope: 'name email',
            redirectURI: 'https://example.com',
            usePopup: true,
            state: '1111111'.toString()//time.
        })
        .use(SnackbarPlugin)
        .use(router)
}
