import { ref } from 'vue';

export const useSnackbar = () => {
    const snackbar = ref(false);
    const snackbarMessage = ref('');
    const snackbarColor = ref('success');
    const snackbarTimeout = ref(3000);

    function showSnackbar(message, color = 'success', timeout = 3000) {
        snackbarMessage.value = message;
        snackbarColor.value = color;
        snackbarTimeout.value = timeout;
        snackbar.value = true;
    }

    function closeSnackbar() {
        snackbar.value = false;
    }

    return {
        snackbar: snackbar,
        snackbarMessage: snackbarMessage,
        snackbarColor: snackbarColor,
        snackbarTimeout: snackbarTimeout,
        showSnackbar,
        closeSnackbar,
    };
};

export default {
    install: (app) => {
        const snackbar = useSnackbar();
        app.provide('$snackbar', snackbar);
    },
};
