<template>
  <v-app>

    <GHomeTopBar />

    <v-main>
      <section id="intro">
        <v-parallax
          :src="imageLink.sub_main"
          height="500"
        >
          <div class="d-flex flex-column fill-height justify-center align-center text-white">
            <h1
              class="text-h4 font-weight-bold text-center mb-4"
              style="text-shadow: 3px 2px #000000"
            >
              The social network for epic content
            </h1>
            <h4
              class="subheading "
              style="text-shadow: 2px 2px #000000"
            >
              Unlesh your creativity without limitations
            </h4>
            <v-btn
              class="mt-5"
              color="primary"
              size="large"
              href="/access"
            >
              Get Started
            </v-btn>
          </div>
        </v-parallax>
      </section>

      <!-- How it works -->
      <section id="howItWorks">
        <v-container>
          <v-row
            align-content="stretch"
            class="my-16"
          >
            <v-col cols="12">
              <div class="text-center">
                <h2 class="text-h4">Our platform provides a variety of features to simplify and streamline your business
                  processes</h2>

              </div>
            </v-col>
            <v-col
              sm="12"
              md="4"
              cols="12"
              v-for="(card, index) in cards"
              :key="index"
            >
              <v-card
                class="bg-primary rounded"
                :title="card.title"
                height="300px"
              >
                <template v-slot:prepend>
                  <v-icon
                    size="xx-large"
                    :icon="card.icon"
                  ></v-icon>
                </template>
                <v-card-text>{{ card.text }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section>
        <v-parallax
          :src="imageLink.main"
          height="380"
          align-content="strech"
        >
          <div class="d-flex flex-column fill-height justify-center align-center text-white text-center">
            <!-- <div class="text-h5 ">
              Endorfine is a social network that allows everyone to reach a huge audience with a tap
            </div>
            <em>With the power of Endorfine you don't need to be famous or post pics of cute cats in order to get
              visibility</em>
            <v-btn
              class="mt-5"
              color="primary-lighten-1"
              size="large"
              href="/access"
            >
              Get More Info
            </v-btn> -->
          </div>
        </v-parallax>
      </section>


      <!-- Pricing -->
      <GPricing />

      <!-- API -->
      <section id="API">
        <v-divider></v-divider>
        <v-container class="py-16">
          <v-row
            class="my-5"
            fluid
          >
            <v-col
              md="6"
              sm="12"
              class="text-center"
            >
              <v-icon
                size="200px"
                icon="mdi-api"
                color="primary"
              ></v-icon>
            </v-col>
            <v-col
              md="6"
              sm="12"
            >
              <v-card elevation="0">
                <v-card-item>
                  <v-card-title>
                    <div class="text-h4">Integration</div>
                  </v-card-title>
                </v-card-item>
                <v-card-text>
                  Are you a creative person? Do you like techy stuff? Complete the email form by writing your skills and
                  interests
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    variant="outlined"
                    block
                    to="/apiDocumentation"
                    v-text="this.$t('APIgotoDocumentation')"
                  ></v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <!-- Social Media -->
      <section>
        <v-parallax
          :src="imageLink.social_cover"
          height="380"
        >
          <div class="d-flex flex-column fill-height justify-center align-center text-white">
            <v-row class="text-white">
              <v-col cols="12">
                <div class="text-h5 text-center mt-16">We are dropping cool news and opportunities on socials
                </div>
              </v-col>
              <v-col cols="12">
                <v-row justify="space-around">
                  <v-col>
                    <v-icon class="text-h3">mdi-facebook</v-icon>
                  </v-col>
                  <v-col>
                    <v-icon class="text-h3">mdi-linkedin</v-icon>
                  </v-col>
                  <v-col>
                    <v-icon class="text-h3">mdi-twitter</v-icon>
                  </v-col>
                  <v-col>
                    <v-icon class="text-h3">mdi-instagram</v-icon>
                  </v-col>
                  <v-col>
                    <v-icon class="text-h3">mdi-facebook</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-parallax>
      </section>

      <!-- Contanct -->
      <section id="contact">
        <v-container class="mb-16">
          <v-row justify="center">
            <v-col
              cols="12"
              class="mt-5"
            >
              <div class="text-h4">Are you amazed? Stay tuned!</div>
              <br>
              <div>We are lunching the beta in a few time. If you want to be one of the first Endorfine users we will
                email you as soon as we're ready. In the beginning only few people will test before the launch. Let us
                know how Endorfine will help you!</div>
            </v-col>
            <v-col
              cols="12"
              md="8"
              sm="12"
            >
              <v-card elevation="0">
                <v-card-text>
                  <v-row
                    align="center"
                    justify="center"
                  >
                    <v-col cols="12">
                      <v-text-field
                        v-model="nameContact"
                        label="Name"
                        variant="outlined"
                        density="compact"
                        color="primary"
                        autocomplete="false"
                      />
                      <v-text-field
                        v-model="emailContact"
                        label="Email"
                        :rules="emailRules"
                        variant="outlined"
                        density="compact"
                        color="primary"
                        autocomplete="false"
                      />
                      <v-text-field
                        v-model="subjectContact"
                        label="Subject"
                        variant="outlined"
                        density="compact"
                        color="primary"
                        autocomplete="false"
                      />
                      <v-textarea
                        v-model="messageContact"
                        label="Message"
                        :rules="messageRules"
                        variant="outlined"
                        counter
                        color="primary"
                        autocomplete="false"
                      />
                      <v-btn
                        color="primary-lighten-1"
                        block
                        v-text="this.$t('contactSend')"
                      ></v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </section>

      <DefaultFooter />
    </v-main>
  </v-app>
</template>

<script>
import GHomeTopBar from '@/components/GHomeTopBar'
import GPricing from '@/components/GPricing'
import DefaultFooter from '@/components/DefaultFooter'

export default {
  name: "GHome",
  components: {
    GHomeTopBar,
    GPricing,
    DefaultFooter,
  },
  data: function () {
    return {
      imageLink: {
        main: require('@/assets/signature1.jpg'),
        sub_main: require('@/assets/signature9.jpg'),
        logo: "@/assets/eSignGold-logos_transparent.svg",
        api_logo: "https://icon-library.com/images/api-icon/api-icon-9.jpg",
        social_cover: require('@/assets/signature5.jpg'),
      },
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      cards: [{ icon: 'mdi-fountain-pen-tip', title: 'e-Sign / Electronic Signatures', text: ' Show your stuff to the whole community of Endorfine not only to your mum or your friends. We love making good content viral. In this moment Endorfine is used by artists who are not famous but that want to share their works to the world. Unfortunately with other social networks this is hard, slow and sometime expensive.' },
      { icon: 'mdi-transfer-right', title: 'Transaction Management', text: 'Imagine if you can directly speak with the world\'s population. Don\'t you think it would be easier to find nice people to interact with? Endorfine is both local and global and help you to connect without limitations with people from your city, your state and your universe! ' },
      // { icon: 'mdi-transit-connection-variant', title: 'API', text: 'Imagine if you can directly speak with the world\'s population. Don\'t you think it would be easier to find nice people to interact with? Endorfine is both local and global and help you to connect without limitations with people from your city, your state and your universe! ' },
      { icon: 'mdi-format-float-left', title: 'Form Management', text: 'Imagine if you can directly speak with the world\'s population. Don\'t you think it would be easier to find nice people to interact with? Endorfine is both local and global and help you to connect without limitations with people from your city, your state and your universe' }],
      nameContact: "",
      subjectContact: "",
      emailContact: "",
      emailRules: [
        v => {
          return !!v || "E-mail is required";
        },
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      messageContact: "",
      messageRules: [
        v => {
          return v.length <= 25 || 'Max 25 characters'
        }
      ]

    };
  },
  methods: {

  },

  computed: {
    imgHeight: function () {
      var offset = 320;
      console.log("new image height is " + (this.pageHeight - offset));
      return this.pageHeight - offset;
    }
  }


};
</script>


