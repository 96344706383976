<template>
    <v-app-bar
        color="white accent-4"
        fixed
    >
        <v-menu
            left
            bottom
            v-if="$vuetify.display.mobile"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    icon="mdi-dots-vertical"
                    v-bind="props"
                ></v-btn>
            </template>

            <v-list dense>
                <v-list-item
                    v-for="item in goToMenuRightItems"
                    :key="item.text"
                    variant="text"
                    :href="item.goToId"
                >
                    <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                    </template>

                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-for="item in linkMenuRightItems"
                    :key="item.text"
                    :to="item.link"
                    link
                >
                    <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                    </template>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>

                </v-list-item>
            </v-list>
        </v-menu>

        <v-img
            class="mx-2"
            :src="appLogo"
            width="300"
        ></v-img>
        <v-toolbar-items v-if="!$vuetify.display.mobile">
            <v-btn
                v-for="item in goToMenuLeftItems"
                :key="item.text"
                variant="text"
                :href="item.goToId"
            >{{ item.text }}</v-btn>

        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items v-if="!$vuetify.display.mobile">
            <v-btn
                v-for="item in goToMenuRightItems"
                :key="item.text"
                variant="text"
                :href="item.goToId"
            >{{ item.text }}</v-btn>
            <v-btn
                v-for="item in linkMenuRightItems"
                :key="item.text"
                :to="item.link"
                :color="item.color"
                variant="flat"
            >{{ item.text }}</v-btn>
        </v-toolbar-items>


    </v-app-bar>
</template>

<script>
export default {
    name: 'GHomeTopBar',
    props: {
        msg: String
    },
    data() {
        return {
            appLogo: require('@/assets/eSignGold-logos_transparent1.png'),
            goToMenuLeftItems: [
                { text: this.$t('howItWorksMenu'), goToId: '#howItWorks', color: 'white' },
                { text: this.$t('princingMenu'), goToId: '#pricing', color: 'white' },
                { text: this.$t('contanctMenu'), goToId: '#contact', color: 'white' },
                { text: this.$t('apiMenu'), goToId: '#API', color: 'white' },
            ],
            goToMenuRightItems: [
                { text: this.$t('salesMenu'), color: 'white', icon: 'mdi-account-box-outline', goToId: '#contact' },
                { text: this.$t('buyNowMenu'), color: 'white', icon: 'mdi-shield-star', goToId: '#pricing' },

            ],
            linkMenuRightItems: [
                {
                    text: this.$t('tryForFreeMenu'),
                    link: {
                        name: 'Access',
                        query: { step: '3', plan: '1' }
                    },
                    color: 'primary-lighten-1',
                    icon: 'mdi-coffee-to-go-outline'
                },

                {
                    text: this.$t('signInMenu'),
                    link: {
                        name: 'Access',
                        query: { step: '2' }
                    },
                    color: 'primary',
                    icon: 'mdi-login'
                },
            ],
        }
    }
}
</script>