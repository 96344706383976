<template>
    <section id="pricing">
        <v-container class="py-16">
            <v-row>
                <v-col
                    cols="12"
                    md="3"
                    class="text-center"
                >

                    <v-badge
                        class="w-100"
                        location="top center"
                        color="transparent"
                    >
                        <template v-slot:badge>
                            <v-btn
                                class="px-9 mx-auto"
                                color="primary"
                                rounded="pill"
                                v-text="this.$t('freeTitlePackage')"
                            ></v-btn>
                        </template>
                        <v-card
                            elevation="12"
                            class="rounded pa-3"
                            width="100%"
                        >
                            <v-card-title class="justify-center mt-5">
                                <span class="align-top">$</span>
                                <span class="text-h3">{{ this.$t('freePricePackage') }}</span>
                                <span class="align-top">{{ this.$t('freePlanPackage') }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-list dense>
                                    <v-list-item
                                        v-for="(item, i) in freeFeaturesItems"
                                        :key="i"
                                    >
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon"></v-icon>
                                        </template>

                                        <v-list-item-title
                                            v-text="item.text"
                                            :class="['text-left', item.decoration]"
                                        ></v-list-item-title>

                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    block
                                    variant="flat"
                                    :to="freeButtonLink"
                                >{{ this.$t('freePriceButton') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-badge>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    class="text-center"
                >
                    <v-badge
                        class="w-100"
                        location="top center"
                        color="transparent"
                    >
                        <template v-slot:badge>
                            <v-btn
                                class="px-9"
                                color="primary"
                                rounded="pill"
                                v-text="this.$t('proTitlePackage')"
                            ></v-btn>
                        </template>
                        <v-card
                            elevation="12"
                            class="rounded pa-3"
                            color="primary"
                            width="100%"
                        >

                            <v-card-title class="justify-center mt-5">
                                <sup class="align-top">$</sup>
                                <span class="text-h3">{{ this.$t('proPricePackage') }}</span>
                                <sub class="align-top">{{ this.$t('proPlanPackage') }}</sub>
                            </v-card-title>
                            <v-card-text>
                                <v-list bg-color="primary">
                                    <v-list-item
                                        v-for="(item, i) in proFeaturesItems"
                                        :key="i"
                                    >

                                        <template v-slot:prepend>
                                            <v-icon
                                                :icon="item.icon"
                                                :class="item.decoration"
                                            ></v-icon>
                                        </template>

                                        <v-list-item-title
                                            v-text="item.text"
                                            :class="['text-left', item.decoration]"
                                        ></v-list-item-title>

                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    variant="outlined"
                                    block
                                    :to="proButtonLink"
                                >{{ this.$t('proPriceButton') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-badge>
                    <p class="text-disabled">{{ this.$t('fourteenFreeTrial') }}</p>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    class="text-center"
                >
                    <v-badge
                        class="w-100"
                        location="top center"
                        color="transparent"
                    >
                        <template v-slot:badge>
                            <v-btn
                                class="px-9"
                                color="primary"
                                rounded="pill"
                                v-text="this.$t('teamTitlePackage')"
                            ></v-btn>
                        </template>
                        <v-card
                            elevation="12"
                            class="rounded pa-3"
                            width="100%"
                        >

                            <v-card-title class="justify-center mt-5">
                                <sup class="align-top">$</sup>
                                <span class="text-h3">{{ this.$t('teamPricePackage') }}</span>
                                <sub class="align-top">{{ this.$t('teamPlanPackage') }}</sub>
                            </v-card-title>
                            <v-card-text>
                                <v-list dense>
                                    <v-list-item
                                        v-for="(item, i) in teamFeaturesItems"
                                        :key="i"
                                    >
                                        <template v-slot:prepend>
                                            <v-icon :icon="item.icon"></v-icon>
                                        </template>

                                        <v-list-item-title
                                            class="text-left"
                                            v-text="item.text"
                                        ></v-list-item-title>

                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    block
                                    variant="flat"
                                    :to="teamButtonLink"
                                >{{ this.$t('teamPriceButton') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-badge>
                    <p class="text-disabled">{{ this.$t('fourteenFreeTrial') }}</p>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    class="text-center"
                >
                    <v-badge
                        class="w-100"
                        location="top center"
                        color="transparent"
                    >
                        <template v-slot:badge>
                            <v-btn
                                class="px-9"
                                color="primary"
                                rounded="pill"
                                v-text="this.$t('enterpriseTitlePackage')"
                            ></v-btn>
                        </template>
                        <v-card
                            color="primary"
                            elevation="12"
                            class="rounded pa-3"
                            width="100%"
                        >

                            <v-card-title class="headline mt-5"></v-card-title>
                            <v-card-text>
                                <p>{{ this.$t('enterpriseLookingForMore') }}</p>
                                <p>{{ this.$t('enterpriseTenOrMore') }}</p>
                                <p>{{ this.$t('enterpriseLearnMoreAbout') }}</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    variant="outlined"
                                    block
                                    v-text="this.$t('enterprisePriceButton')"
                                ></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-badge>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    name: 'GPricing',
    props: {
        msg: String
    },
    data() {
        return {
            freeFeaturesItems: [
                { text: this.$t('oneDocumentPerMonth'), icon: 'mdi-text-box-multiple-outline', decoration: '' },
                { text: this.$t('oneSender'), icon: 'mdi-account', decoration: '' },
                { text: this.$t('multipleSigners'), icon: 'mdi-file-edit', decoration: '' },
                { text: this.$t('templatesFeature'), icon: 'mdi-file-multiple', decoration: 'text-decoration-line-through text-disabled' },
                { text: this.$t('branding'), icon: 'mdi-certificate-outline', decoration: 'text-decoration-line-through text-disabled' },
                { text: this.$t('signingLinks'), icon: 'mdi-link-variant', decoration: 'text-decoration-line-through text-disabled' },
                { text: this.$t('teamFeatures'), icon: 'mdi-account-group', decoration: 'text-decoration-line-through text-disabled' },
            ],
            proFeaturesItems: [
                { text: this.$t('unlimitedDocs'), icon: 'mdi-text-box-multiple-outline' },
                { text: this.$t('oneSender'), icon: 'mdi-account' },
                { text: this.$t('multipleSigners'), icon: 'mdi-signature-freehand' },
                { text: this.$t('templatesFeature'), icon: 'mdi-file-multiple' },
                { text: this.$t('branding'), icon: 'mdi-certificate' },
                { text: this.$t('signingLinks'), icon: 'mdi-link-variant' },
                { text: this.$t('teamFeatures'), icon: 'mdi-account-group', decoration: 'text-decoration-line-through' },
            ],
            teamFeaturesItems: [
                { text: this.$t('unlimitedDocs'), icon: 'mdi-text-box-multiple-outline' },
                { text: this.$t('upToNineSenders'), icon: 'mdi-account' },
                { text: this.$t('multipleSigners'), icon: 'mdi-file-edit' },
                { text: this.$t('templatesFeature'), icon: 'mdi-file-multiple' },
                { text: this.$t('branding'), icon: 'mdi-certificate-outline' },
                { text: this.$t('signingLinks'), icon: 'mdi-link-variant' },
                { text: this.$t('teamFeatures'), icon: 'mdi-account-group' },
            ],
            freeButtonLink: {
                name: 'Access',
                query: {
                    step: '3',
                    plan: '1'
                }
            },
            proButtonLink: {
                name: 'Access',
                query: {
                    step: '3',
                    plan: '3'
                }
            },
            teamButtonLink: {
                name: 'Access',
                query: {
                    step: '3',
                    plan: '5'
                }
            },
        }
    }

}
</script>