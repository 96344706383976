// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import i18n from './i18n'

// // Vuetify
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

// const vuetify = createVuetify({
//     components,
//     directives,
// })


// createApp(App).use(i18n).use(store).use(vuetify).use(router).mount('#app')
/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

const app = createApp(App)

// app.config.errorHandler = (err, instance, info) => {
//     // handle error, e.g. report to a service
//     console.log(err, instance, info)
// }

registerPlugins(app)

app.mount('#app')
