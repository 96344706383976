import axios from "axios"
import $cookies from 'vue-cookies'
import router from '@/router'

axios.defaults.withCredentials = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL


axios.interceptors.request.use(
    (config) => {
        const token = $cookies.get('user')?.token

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        if (
            config.url == '/signature' ||
            (config.url == '/envelopes' && config.method == 'post') ||
            (config.url.includes('/envelopes/document/') && !config.url.includes('/envelopes/document/delete')) ||
            (config.url == '/templates' && config.method == 'post') ||
            (config.url.includes('/templates/document') && !config.url.includes('/templates/document/delete')) ||
            (config.url == '/envelopes/invite')
        ) {
            config.headers['Content-Type'] = `multipart/form-data`
        } else {
            config.headers['Content-Type'] = `application/json`
            config.headers['Accept'] = `application/json`
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

//renew token token each request
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if ($cookies.isKey("user") && $cookies.get("user")) {
        $cookies.set('user', $cookies.get('user'))//renew cookie        
    }
    //@To-Do:renew token from response//$cookies.set('user',...$cookies.get('user'), token: response.data.token)
    return response;
}, function (error) {
    //Something went wrong on api call
    if (error?.response?.data?.message == 'Unauthenticated. Info: 0') {//is start session in another instance...
        $cookies.remove('user')
    }
    if (router.currentRoute.value.meta.authRequired && !$cookies.isKey("user")) {//if doenst have cookie live and if is in a path that requires autentication then logback to login
        router.push({ name: 'Access' })
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export const API = {
    access: {
        login(data) {
            return axios.post('/login', data)
        },
        logout() {
            return axios.get('/logout', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                data: {}
            })
        },
        registerOne(data) {
            return axios.post('/register/step1', data)
        },
        registerTwo(data) {
            return axios.post('/register/step2', data)
        },
        loginGoogle(data) {
            return axios.post('/auth/google', data)
        },
        forgotPassword(data) {
            return axios.post('/forgot-password', data)
        },
        verifyTokenPassword(data) {
            return axios.get('/forgot-password/' + data)
        },
        resetPassword(data) {
            return axios.post('/reset-password', data)
        },
        list() {
            return axios.get('/test-endpoint', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                data: {}
            })
        }
    },
    signatures: {
        createSignature(data) {
            return axios.post('/signature', data)
        },
        createSignatureGuest(data) {
            return axios.post('/guest/signature', data)
        },
        getSignaturesList() {
            return axios.get('/signatures')
        },
        deleteSignature(id) {
            return axios.delete('/signature/' + id)
        },
        updateSignature(id, data) {
            return axios.post('/signature/' + id, data)
        },
        updateSignatureGuest(id, data) {
            return axios.post('/guest/signature/' + id, data)
        },
        setSignatureDefault(data) {
            return axios.post('/signature/default/' + data)
        }
    },
    envelope: {
        //docuemnt
        getEnvelopeByID(envelope_id) {
            return axios.get(`/envelopes/${envelope_id}`)
        },
        createDocumentEnvelope(data) {
            return axios.post('/envelopes', data)
        },
        addDocumentEnvelope(id, data) {
            return axios.post('/envelopes/document/' + id, data)

        },
        deleteDocumentEnvelope(envelope_id, document_id) {
            return axios.post(`/envelopes/document/delete/${envelope_id}/${document_id}`)
        },
        deleteDocumentPageEnvelope(envelope_id, document_id, page) {
            return axios.post(`/document/page/delete/${envelope_id}/${document_id}`, page)
        },
        listDocuments() {
            return axios.get('/envelopes')
        },
        addDocumentSigners(envelope_id, data) {
            return axios.post(`/envelopes/signers/${envelope_id}`, data)
        },
        updateEnvelope(envelope_id, data) {
            return axios.put(`/envelopes/${envelope_id}`, data)
        },
        rotateDocumentEnvelope(envelope_id, document_id, data) {
            return axios.post(`/document/page/rotate/${envelope_id}/${document_id}`, data)
        },
        inviteSigners(envelope_id, data) {
            return axios.post(`/envelopes/invite/${envelope_id}`, data)
        }
    },
    envelopeTemplate: {
        //template
        getTemplateByID(envelope_id) {
            return axios.get(`/templates/${envelope_id}`)
        },
        createTemplateEnvelope(data) {
            return axios.post('/templates', data)
        },
        addTemplateEnvelope(id, data) {
            return axios.post('/templates/document/' + id, data)
        },
        updateTemplate(envelope_id, data) {
            return axios.put(`/templates/${envelope_id}`, data)
        },
        deleteTemplateEnvelope(envelope_id, document_id) {
            return axios.post(`/templates/document/delete/${envelope_id}/${document_id}`)
        },
        listTemplates() {
            return axios.get('/templates')
        },
        addTemplateSigners(envelope_id, data) {
            return axios.post(`/templates/signers/${envelope_id}`, data)
        },
    },
    signers: {
        getSignersList(data) {
            return data ? axios.get(`/signers?team_id=${data}`) : axios.get('/signers')
        },
        viewDocument(data) {
            return axios.post('/signers/document/view', data)
        },
        acceptTermsConditions(data) {
            return axios.post('/guest/terms_conditions/accept/' + data)
        },
        declineEnvelope(data) {
            return axios.post('/envelope/action/decline', data)
        },
        finishEnvelope(data) {
            return axios.post('/envelope/action/finish', data)
        }
    },
    folders: {
        createFolder(data) {
            return axios.post('/folders', data)
        },
        folderList() {
            return axios.get('/folders')
        },
        folderDocuments(data) {
            return axios.get('/folders/content' + data)           
        }
    }
}