/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'

const colors = {
  // primary: '#2B5292',
  // secondary: '#9C27b0',
  // accent: '#9C27b0',
  // info: '#00CAE3',
  // error: '#b71c1c',
  // anchor: '#8c9eff',
  background: '#FFFFFF',
  surface: '#FFFFFF',
  primary: '#2B5292',
  'primary-lighten-1': '#496aad',
  secondary: '#03DAC6',
  'secondary-darken-1': '#018786',
  error: '#B00020',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
}

import { VDataTable } from 'vuetify/labs/VDataTable'


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VDataTable,
  },
  // components,
  // directives,
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: 'Results not found',
    },
    VAppBar: {
      flat: true,
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: colors
      },
      dark: {
        dark: true,
        colors: colors
      }
    },
  },
})
